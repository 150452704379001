@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../styles/config'
@import '~bootstrap/scss/mixins'



.search-row
    display: grid
    grid:
        template:
            columns: auto max-content
            areas: 'input buton'
    gap: $base-grid * 2
    .search-row__input
        grid-area: input
    .search-row__button
        grid-area: buton