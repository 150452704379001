@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.shelves-list
    display: grid
    grid:
        row-gap: $base-grid * 8