@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
    
    
// Color system
$primary: #004a8f
$blue-active--big: #3E5ED1
$blue-active--small: #2C5AFF
$danger: #E3423C
$success: #3AC65A
$link-color: #2C5AFF
$passive: #00b1eb
$link: #f39204

$theme-colors: ("primary": $primary, "secondary": #DCE6EF, "success": $success, "info": $info, "warning": $warning, "danger": $danger, "light": $light, "dark": $dark)

// Body
// Settings for the `<body>` element.
// $body-bg: #E6E9F2
$body-color: #262A2E
// Input
// $input-bg: #B0CBE3
// $input-focus-bg: white
// $input-border-color: white

// Font system
$font-family-base: 'Arial', Helvetica, sans-serif
$font-size-base: 1.125rem

// Headings
$headings-font-family:        'Raleway', sans-serif
$headings-font-weight:        700
$h1-font-size:                36px
$h2-font-size:                28px
$h3-font-size:                24px
$h4-font-size:                20px
$h5-font-size:                18px
$h6-font-size:                16px

$display1-size:               72px

// Radiuses
$border-radius: 0

// Grid system
$base-grid: 8px
$container-sm: 100%
$container-md: 100%
$container-lg: 100%
$container-xl: 1440px
$container-xxl: 1920px
$container-max-widths: (sm: $container-sm, md: $container-md, lg: $container-lg, xl: $container-xl)

@font-face
  font-family: 'DGUVMetaWeb Bold'
  src: url('/fonts/DGUVMetaWeb-Bold.woff') format('woff')
  font-weight: 400
  font-style: bold