@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.cards-carousel
    margin:
        top: $base-grid * 2
    // Elems
    .cards-carousel__info-row
        display: flex
        justify-content: space-between
        align-items: baseline
        @include media-breakpoint-down(sm)
            margin:
                bottom: $base-grid
    .cards-carousel__title
        font:
            size: 1.2rem
            weight: bold
        margin: 0
    .cards-carousel__show-more-button
        border: none
        outline: none
        background: transparent
        color: inherit
    .cards-carousel__carousel
        pointer-events: none
        @include media-breakpoint-up(lg)
            margin:
                left: $base-grid * -2
                right: $base-grid * -2
                top: -$base-grid
        // Bootstrap overrides
        .carousel-control-prev,
        .carousel-control-next
            pointer-events: all
            top: 50%
            width: $base-grid * 6
            height: $base-grid * 6
            transform: translateY(-50%)
            background-color: $passive
        .carousel-control-prev
            @include media-breakpoint-up(xl)
                left: $base-grid * 3
        .carousel-control-next
            @include media-breakpoint-up(xl)
                right: $base-grid * 3
        .carousel-indicators
            pointer-events: all
            bottom: 0
            transform: translateY(100%)
            @include media-breakpoint-down(md)
                transform: translateY(180%)
            li
                background-color: rgba(black, .6)
        // States
        &--non-carousel
            .carousel-control-prev,
            .carousel-control-next,
            .carousel-indicators
                opacity: 0
                pointer-events: none
    .cards-carousel__carousel-item
        pointer-events: none
        @include media-breakpoint-up(lg)
            padding: 
                top: $base-grid * 3
                bottom: $base-grid * 2
                left: $base-grid * 2
                right: $base-grid * 2
    .cards-carousel__carousel-cards
        pointer-events: all
    // States
    & + .cards-carousel
        margin:
            top: $base-grid * 4
    // Mobile
    .cards-carousel__list
        width: 100%
        &--mobile
            overflow-x: auto
            display: flex
            gap: $base-grid * 2
            .cards-carousel__card
                width: 220px
                flex-shrink: 0