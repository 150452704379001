@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.list-loader
    list-style: none
    margin: 0
    padding: 0
    display: grid
    grid:
        template:
            columns: repeat(2, 1fr)
        gap: $base-grid * 2
    @include media-breakpoint-up(sm)
        grid:
            template:
                columns: repeat(3, 1fr)
    @include media-breakpoint-up(md)
        grid:
            template:
                columns: repeat(4, 1fr)
    @include media-breakpoint-up(lg)
        grid:
            template:
                columns: repeat(5, 1fr)
    @include media-breakpoint-up(xl)
        grid:
            template:
                columns: repeat(6, 1fr)
    .list-loader__placeholder-image
        padding-top: 140%
        background-color: #EFEFEF
    .list-loader__placeholder-title
        heigth: 40px
        width: 90%
        background-color: #EFEFEF