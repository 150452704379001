@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.shelf-box
    display: grid
    grid:
        template:
            columns: 1fr
            areas: 'title ' 'show-more' 'description' 'list'
        gap: $base-grid * 2
    @include media-breakpoint-up(sm)
        grid:
            template:
                columns: minmax(0, 1fr) minmax(0, 1fr)
                areas: 'title show-more' 'description description' 'list list'
    @include media-breakpoint-up(lg)
        grid:
            template:
                columns: 3fr 9fr
                rows: auto auto minmax(0, 1fr)
                areas: 'title title' 'description list' 'show-more list'
            column-gap: $base-grid * 4
    @include media-breakpoint-up(xl)
        column-gap: $base-grid * 6
    // Elements
    .shelf-box__header-title
        grid-area: title
        margin: 0
            bottom: $base-grid
    .shelf-box__description
        grid-area: description
        margin: 0
    .shelf-box__show-more
        grid-area: show-more
        font-size: 14px
        margin: 0
            bottom: $base-grid
        align-self: start
        justify-self: start
        @include media-breakpoint-between(sm, md)
            align-self: end
            justify-self: end
    .shelf-box__list
        grid-area: list
        &--desktop
            display: grid
            grid:
                template:
                    columns: repeat(4, 1fr)
            gap: $base-grid * 2
        &--mobile
            overflow-x: auto
            display: flex
            gap: $base-grid * 2
            .shelf-box__card
                width: 220px
                flex-shrink: 0
    &:before
        grid:
            column: 1 / -1
            row: 1
        content: ''
        display: block
        height: 1px
        background-color: #D6D6D6
        justify-self: stretch
        align-self: end