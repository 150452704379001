@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.heroscreen
    color: white
    padding:
        top: $base-grid * 4
        bottom: $base-grid * 8
        left: $base-grid * 2
        right: $base-grid * 2
    position: relative
    @include media-breakpoint-down(md)
        max-width: 100%
        overflow: hidden
    @include media-breakpoint-up(lg)
        display: grid
        grid:
            template: 
                columns: 1fr 2fr repeat(3, 2fr) 2fr 1fr
                rows: repeat(6, 1fr)
            gap: $base-grid * 3
    // Elements
    &:before
        content: ''
        display: block
        height: 500vw
        width: 500vw
        position: absolute
        left: 50%
        bottom: 0
        z-index: -1
        transform: translateX(-50%)
        border-radius: 50%
        background-color: #1F429D
    .heroscreen__content
        @include media-breakpoint-up(lg)
            grid:
                column: 3 / span 3
                row: 2 / span 3
            align-self: end
            
    .heroscreen__title
        line-heigth: 1
        font-size: 48px
        @include media-breakpoint-up(lg)
            font-size: 70px
            text-align: center
            text-transform: uppercase
            line-height: .9
        @include media-breakpoint-up(xl)
            font-size: 120px
    .heroscreen__description
        @include media-breakpoint-up(lg)
            text-align: center
    .heroscreen__card-image
        grid:
            column:
                end: span 1
            row-end: span 2
        &:nth-child(2)
            grid:
                row-start: 5
                column-start: 4
        &:nth-child(3)
            grid:
                row-start: 5
                column-start: 3
        &:nth-child(4)
            grid:
                row-start: 5
                column-start: 5
                
        &:nth-child(5)
            grid:
                row-start: 2
                column-start: 2
        &:nth-child(6)
            grid:
                row-start: 2
                column-start: 6
        &:nth-child(7)
            grid:
                row-start: 4
                column-start: 2
        &:nth-child(8)
            grid:
                row-start: 4
                column-start: 6
                
        &:nth-child(9)
            grid:
                row-start: 3
                column-start: 6
            transform: translateX(calc(100% + #{$base-grid * 3}))
        &:nth-child(10)
            grid:
                row-start: 3
                column-start: 2
            transform: translateX(calc(-100% - #{$base-grid * 3}))
        
        &:nth-child(11)
            grid:
                row-start: 1
                column-start: 2
            transform: translateX(calc(-100% - #{$base-grid * 3}))
        &:nth-child(12)
            grid:
                row-start: 1
                column-start: 6
            transform: translateX(calc(100% + #{$base-grid * 3}))
        &:nth-child(13)
            grid:
                row-start: 2
                column-start: 6
            transform: translateX(calc(200% + #{$base-grid * 6}))
        &:nth-child(14)
            grid:
                row-start: 2
                column-start: 2
            transform: translateX(calc(-200% - #{$base-grid * 6}))
        &:nth-child(15)
            grid:
                row-start: 2
                column-start: 2
            transform: translateX(calc(-200% - #{$base-grid * 6})) translateY(calc(-100% - #{$base-grid * 3}))
    .heroscreen__cards-list
        overflow-x: auto
        display: flex
        gap: $base-grid * 2
        .heroscreen__card
            width: 220px
            flex-shrink: 0
    .heroscreen__search
        width: 100%
        margin-bottom: $base-grid * 2
    .heroscreen__categories
        margin-bottom: $base-grid * 2
    .heroscreen__link
        background-color: $link
        color: white
        padding: $base-grid $base-grid * 2
        display: inline-block
        margin-bottom: $base-grid * 2
        @include media-breakpoint-up(lg)
            margin-bottom: 0