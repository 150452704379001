@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'

.CookieConsent
  align-items: baseline
  background: #edebeb
  color: black
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  right: 0px
  position: fixed
  z-index: 1001
  margin: 10px
  border: 2px solid $primary

  a
    color: $primary

  @include media-breakpoint-up(md)
    width: 400px

  div
    padding: 10px

  button#rcc-decline-button
    background: white
    border: 0px
    border-radius: 0px
    box-shadow: none
    color: $primary
    cursor: pointer
    flex: 0 0 auto
    padding: 5px 10px
    margin: 15px

    &:hover
      color: $danger

  button#rcc-confirm-button
    background: white
    border: 0px
    border-radius: 0px
    box-shadow: none
    color: $primary
    cursor: pointer
    flex: 0 0 auto
    padding: 5px 10px
    margin: 15px

    &:hover
      color: $warning
