@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../styles/config'
@import '~bootstrap/scss/mixins'



.desktop-category-dropdown-button
    display: flex
    font-size: 20px
    width: 100%
    align-items: center
    justify-content: space-between
    height: $base-grid * 5
    padding:
        left: $base-grid * 2
        right: $base-grid * 2
    background-color: white
    border: 1px solid #ECECEC
        radius: 2px 
    // Elements
    .desktop-category-dropdown-button__children
        display: -webkit-box
        -webkit-line-clamp: 1
        -webkit-box-orient: vertical
        overflow: hidden
    .desktop-category-dropdown-button__icon
        flex-shrink: 0