@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.filter-categories
    display: flex
    gap: $base-grid * 1.5
    .filter-categories__category
        flex:
            grow: 1
            shrink: 1
        width: 100%
    .filter-categories__category-title
        font-size: 20px
        display: flex
        font-weight: 400
        align-items: center
        justify-content: space-between
        height: $base-grid * 5
        padding:
            left: $base-grid * 2
            right: $base-grid * 2
        background-color: white
        border: 1px solid #ECECEC
            radius: 2px 
        margin:
            bottom: $base-grid * 2
    .filter-categories__category-counter
        display: inline-flex
        margin-left: $base-grid
        padding: 3px 7px
        background-color: $blue-active--small
        color: white
        font-size: 10px
        border-radius: 3px
        line-height: 1
        vertical-align: middle
    .filter-categories__dropdown-menu
        width: 100%
        min-width: 300px