@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'


.flip-card
  display: flex
  flex:
    direction: column
  transition: all .2s
  color: inherit
  // Elems
  .flip-card__button
    border: none
    background-color: transparent
    position: absolute
    right: 0
    top: 0
    padding: $base-grid * 2

    span
      display: flex
      align-items: center
      justify-content: center
      transition: all .2s
      width: $base-grid * 3
      height: $base-grid * 3
      border-radius: 50%

    &--add-to-favorites
      span
        background-color: $green
        color: white

    &--remove-from-favorites
      span
        background-color: $red
        color: white

  .flip-card__icon
    display: inline-flex
    align-items: center
    justify-content: center
    width: $base-grid * 3
    height: $base-grid * 3
    border: none
      radius: 50%
    padding: calc($base-grid / 1.5)

    &--featured
      position: absolute
      left: $base-grid * 2
      top: $base-grid * 2
      background-color: #fdd017
      color: white
      box-shadow: 0 $base-grid $base-grid * 2 $base-grid * -1.2 rgba(black, .5)

  .flip-card__image-area
    position: relative
    cursor: pointer

    &:before
      content: ''
      display: block
      height: 95%
      width: 95%
      background-color: rgba(black, .15)
      position: absolute
      left: 50%
      top: 50%
      transform: translate(-50%, -45%)
      filter: blur(15px)
      transition: all .3s

  .flip-card__flip-area
    transform-style: preserve-3d
    transform: rotateY(180deg)

  .flip-card__flip-side
    backface-visibility: hidden
    background-color: white
    height: 100%
    width: 100%
    transition: all .5s

    &--front
      position: absolute
      left: 0
      top: 0
      transform: rotateY(-180deg)

    &--back
      z-index: -1
      display: flex
      flex-direction: column
      align-items: flex-start
      position: absolute
      left: 0
      top: 0
      padding: $base-grid * 2
      background-color: lightgray

  .flip-card__image-area-link
    display: block
    position: relative
    padding:
      bottom: 140%

  .flip-card__image
    position: absolute
    left: 0
    top: 0
    height: 100%
    width: 100%
    object-fit: cover

  .flip-card__image-icon
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    font-size: 2em
    color: lightgray

  .flip-card__info
    padding: $base-grid
    color: inherit
    text-decoration: inherit
    cursor: pointer
    z-index: 1

  .flip-card__type
    display: inline-flex
    background-color: $gray-800
    color: white
    border-radius: calc($base-grid / 3)
    padding: calc($base-grid / 3) $base-grid
    font-size: .8em
    text-transform: capitalize
    pointer-events: none
    user-select: none
    transition: all .2s

    &--hide-flip
      position: absolute
      left: $base-grid
      bottom: $base-grid
      top: auto

  .flip-card__title
    width: 100%
    color: black
    font:
      size: 0.9rem
    line-height: 1.1
    text-decoration: none
    transition: all .2s

    &--cutted
      height: 1.1rem * 2
      color: inherit
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
      overflow: hidden
      word-break: break-word

  .flip-card__subtitle
    width: 100%
    color: inherit
    font:
      size: .8rem
    line-height: 1
    text-decoration: none
    display: -webkit-box
    -webkit-line-clamp: 1
    -webkit-box-orient: vertical
    overflow: hidden
  // States
  &--flipped
    text-decoration: none
    color: inherit

    .flip-card__image-area
      &:before
        animation: .5s linear 0s move-shadow
          fill-mode: both
          direction: alternate

    .flip-card__flip-area
      animation: .5s linear 0s flip-card
        fill-mode: both
        direction: alternate

    .flip-card__flip-side
      &--back
        background-color: white

      & > .flip-card__title
        margin-top: 30px

    .flip-card__type.flip-card__type--hide-flip
      transform: translateY(100%)
      opacity: 0

    .flip-card__button
      animation: .5s linear 0s move-button-right-top
        fill-mode: both
        direction: alternate

    .flip-card__icon
      opacity: 0

    .flip-card__info
      opacity: .2
      z-index: -1


@keyframes flip-card
  5%
    transform: rotateY(130deg) translate(-5%, 5%)
  40%
    transform: rotateY(80deg) translate(-50%, 5%) scale(1.2) skew(0deg, 5deg)
    box-shadow: $base-grid*.5 $base-grid $base-grid*5 rgba(black, .15), 0 $base-grid $base-grid*5 $base-grid*-1 rgba(black, .5)
  100%
    transform: rotateY(0deg)

@keyframes move-button-right-top
  15%
    transform: translate(5%, -5%)
  45%
    transform: translate(15%, -15%) scale(1.2)
  100%
    transform: translate(0%, 0%)

@keyframes move-shadow
  0%
    height: 95%
    width: 95%
  45%
    height: 80%
    width: 20%
  100%
    height: 95%
    width: 95%