@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'


.video-card
  display: flex
  flex:
    direction: column
  transition: all .2s
  color: inherit
  // Elems
  .video-card__button
    border: none
    background-color: transparent
    position: absolute
    right: 0
    top: 0
    padding: $base-grid * 2

    span
      display: flex
      align-items: center
      justify-content: center
      transition: all .2s
      width: $base-grid * 3
      height: $base-grid * 3
      border-radius: 50%

    &--add-to-favorites
      span
        background-color: $green
        color: white

    &--remove-from-favorites
      span
        background-color: $red
        color: white

  .video-card__icon
    display: inline-flex
    align-items: center
    justify-content: center
    width: $base-grid * 3
    height: $base-grid * 3
    border: none
      radius: 50%
    padding: calc($base-grid / 1.5)

    &--featured
      position: absolute
      left: $base-grid * 2
      top: $base-grid * 2
      background-color: #fdd017
      color: white
      box-shadow: 0 $base-grid $base-grid * 2 $base-grid * -1.2 rgba(black, .5)

  .video-card__image-area
    position: relative
    box-shadow: $base-grid * .5 $base-grid $base-grid * 2 rgba(black, .15), 0 $base-grid $base-grid * 2 $base-grid * -1 rgba(black, .3)
    transition: all .2s cubic-bezier(0.0, 0.0, 0.58, 1.0)
    background-color: white
    cursor: pointer

  .video-card__image-area-link
    display: block
    position: relative
    padding:
      bottom: 140%

  .video-card__image
    position: absolute
    left: 0
    top: 0
    height: 100%
    width: 100%
    object-fit: cover

  .video-card__image-icon
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    font-size: 2em
    color: lightgray

  .video-card__info
    padding: $base-grid
    color: inherit
    text-decoration: inherit
    cursor: pointer

  .video-card__type
    background-color: $gray-800
    color: white
    border-radius: calc($base-grid / 3)
    padding: calc($base-grid / 3) $base-grid
    position: absolute
    left: $base-grid
    bottom: $base-grid
    top: auto
    font-size: .8em
    text-transform: capitalize
    pointer-events: none
    user-select: none
    transition: all .2s

  .video-card__title
    width: 100%
    color: inherit
    font:
      size: 1rem
    line-height: 1.1
    text-decoration: none
    transition: all .2s

    &--cutted
      height: 1.1rem * 2
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
      overflow: hidden
      word-break: break-word

  .video-card__subtitle
    width: 100%
    color: inherit
    font:
      size: .8rem
    line-height: 1
    text-decoration: none
    display: -webkit-box
    -webkit-line-clamp: 1
    -webkit-box-orient: vertical
    overflow: hidden
  // States
  &:hover
    text-decoration: none
    color: inherit

    .video-card__flip-area
      animation: .4s linear 0s both open_video-card

    @include media-breakpoint-up(xl)
      &:not(.video-card--row)
        .video-card__image-area
          transform: scale(1.05) translateY($base-grid * -1)
          box-shadow: 0 $base-grid * 2 $base-grid * 4 $base-grid * -1 rgba(black, .5)