@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../styles/config'
@import '~bootstrap/scss/mixins'



.empty-search
    display: flex
    flex:
        direction: column
    align-items: center
    justify-content: center
    padding: 100px $base-grid
    text-align: center
    height: 100%
    // Elements
    .empty-search__icon
        font-size: 3em
        opacity: .4
    .empty-search__description
        margin:
            top: $base-grid * 2
        opacity: .6
        user-select: none