@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'
    
    

.video-modal
    position: fixed
    top: 0
    left: 0
    display: grid
    grid-template-areas: 'video' 'title' 'buttons' 'description'
    gap: $base-grid * 2
    width: 100%
    height: 100%
    background-color: #242424
    color: white
    z-index: 9998
    padding:
        bottom: $base-grid * 6
    overflow-y: auto
    -ms-overflow-style: none
    scrollbar-width: none
    padding:
        top: $base-grid * 7
    &::-webkit-scrollbar 
        display: none
    @include media-breakpoint-up(lg)
        display: grid
        grid:
            template:
                columns: calc((100% - #{$container-lg}) / 2) 60ch minmax(0, 1fr) 1fr calc((100% - #{$container-lg}) / 2) 
                areas: '. video video title .' '. video video buttons .' '. description . . .'
            gap: $base-grid * 4
    @include media-breakpoint-up(xl)
        grid:
            template:
                columns: calc((100% - #{$container-xl}) / 2) 70ch minmax(0, 1fr) 1fr calc((100% - #{$container-xl}) / 2)
    // Elements
    .video-modal__close-button
        position: fixed
        top: 0
        right: 0
        z-index: 9999
        background-color: transparent
        color: white
        font-size: 30px
        border: 0
        height: $base-grid * 7
        width: $base-grid * 7
        @include media-breakpoint-up(lg)
            height: $base-grid * 9
            width: $base-grid * 9
    .video-modal__frame
        grid-area: video
        width: 100%
        height: auto
        padding:
            bottom: 56%
        position: relative
        background-color: black
        iframe
            position: absolute
            left: 0
            top: 0
            height: 100%
            width: 100%
    .video-modal__title
        grid-area: title
        @include media-breakpoint-down(md)
            padding:
                left: $base-grid * 2
                right: $base-grid * 2
            h1
                font-size: 1.5rem
    .video-modal__recomendation
        display: block
        margin-top: $base-grid * 2
    .video-modal__recomendation-icon
        color: #FFE601
        vertical-align: baseline
        margin-right: $base-grid
    .video-modal__buttons
        grid-area: buttons
        align-self: end
        @include media-breakpoint-down(md)
            padding:
                left: $base-grid * 2
                right: $base-grid * 2
    .video-modal__description
        grid-area: description
        @include media-breakpoint-down(md)
            margin-top: $base-grid * 6
            padding:
                left: $base-grid * 2
                right: $base-grid * 2
    // States
    &-enter 
        opacity: 0
        transform: translateY(50%) scale(1.3)
    &-enter-active
        opacity: 1
        transform: translateY(0)
        transition: all .4s
    &-exit
        opacity: 1
    &-exit-active
        opacity: 0
        transform: translateY(-50%) scale(.8)
        transition: all .4s