@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.filter-header
    background-color: #ECECEC
    display: grid
    grid: 
        template:
            columns: 2fr 42px max-content
            rows: auto auto
            areas: 'categories categories categories' 'search toggle clear'
        gap: $base-grid * 3
    align-items: start
    @include media-breakpoint-up(lg)
        grid: 
            template:
                columns: 8fr 2fr 42px max-content
                rows: auto
                areas: 'categories search toggle clear'
    .filter-header__categories
        grid-area: categories
        justify-self: stretch
    .filter-header__search
        grid-area: search
        justify-self: stretch
    .filter-header__toggle
        grid-area: toggle
    .filter-header__claer
        grid-area: clear
    .filter-header__helper
        grid-area: helper
        align-self: start
        padding-top: $base-grid * 2
        margin-bottom: $base-grid * 2
        border-top: 1px solid #BEBEBE
    // States
    &--opened
        grid: 
            template:
                rows:  auto auto auto
                areas: 'categories categories categories' 'helper helper helper' 'search toggle clear'
        overflow-y: auto
        max-height: calc(100vh - 60px)
        @include media-breakpoint-down(md)
            .filter-header__search
                margin-bottom: $base-grid * 2
        @include media-breakpoint-up(lg)
            grid: 
                template:
                    rows: auto minmax(0, 1fr)
                    areas: 'categories search toggle clear' 'categories helper helper helper'