@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.cards-list
    max-width: 100%
    //States
    &.cards-list--rows
        display: grid
        grid:
            template:
                columns: repeat(auto-fill, minmax(300px, 1fr))
            gap: $base-grid * 2
        @include media-breakpoint-up(lg)
            grid:
                template:
                    columns: repeat(auto-fill, minmax(40%, 1fr))
    &.cards-list--grid
        display: grid
        grid:
            template:
                columns: repeat(2, 1fr)
            gap: $base-grid * 2
        @media (max-width: 320px)
            grid:
                template:
                    columns: 1fr
        @include media-breakpoint-up(sm)
            grid:
                template:
                    columns: repeat(3, 1fr)
        @include media-breakpoint-up(md)
            grid:
                template:
                    columns: repeat(4, 1fr)
        @include media-breakpoint-up(lg)
            grid:
                template:
                    columns: repeat(5, 1fr)
        @include media-breakpoint-up(xl)
            grid:
                template:
                    columns: repeat(6, 1fr)