@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.category-loader
    padding: $base-grid * 2
    .category-loader__title
        height: 30px
        margin-bottom: 20px
        max-width: 100%
        width: 300px
        background-color: #EFEFEF
    .category-loader__list
        // Nothing