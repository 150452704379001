@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'


.sort
  display: flex
  align-items: center
  // Elems
  .sort__order-by
    font-size: 16px
    margin-right: calc($base-grid / 2)
    line-height: 1.1
    @include media-breakpoint-down(xs)
      display: none

  .sort__dropdown-menu
    left: auto
    top: 0

  .sort__dropdown-menu-item
    cursor: pointer