@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../styles/config'
@import '~bootstrap/scss/mixins'


.page
  .page__body
    margin: 0
    overflow-x: hidden

  .page__header
    z-index: 1000
    box-shadow: $base-grid $base-grid $base-grid * 2 (-$base-grid * 1.3) rgba(black, .1)
    background-color: white
    position: sticky
    top: 0

  .page__filter-row
    position: sticky
    position: -webkit-sticky
    top: 53px
    z-index: 999
    flex-shrink: 0
    padding: 10px $base-grid * 2
    box-shadow: $base-grid $base-grid $base-grid * 2 rgba(black, .05)

  .page__content
    min-height: calc(100vh - 42px - 65px)
    padding:
      top: $base-grid * 4
      bottom: $base-grid * 4

  .page__loader, .page__content
    flex-grow: 10
    display: flex
    flex-direction: column
    align-items: stretch

  .page__hero
    overflow-x: hidden
// @include media-breakpoint-down(md)
//     background-color: #1F429D