@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../config'
@import '~bootstrap/scss/mixins'


.view
    min-height: 100%
    width: 100%
    .view__info-row
        display: flex
        justify-content: space-between
        align-items: flex-end
        @include media-breakpoint-down(sm)
            margin:
                bottom: $base-grid
    .view__title
        font:
            size: 1.2rem
            weight: bold
        margin: 0
    .view__group-title
        font:
            size: .8rem
        margin: 0
    .view__content
        margin-top: $base-grid * 2