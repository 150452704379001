@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../styles/config'
@import '~bootstrap/scss/mixins'


.desktop-view-button
  padding: calc($base-grid / 2)
  margin: calc($base-grid / 2)
  border: 0
  background: transparent
  color: #2C5AFF
  font-size: inherit
  text-decoration: underline