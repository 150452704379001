@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.global-search
    display: flex
    align-items: center
    height: $base-grid * 5
    padding:
        left: $base-grid * 2
        right: $base-grid * 2
    background-color: white
    border: 1px solid #ECECEC
        radius: 2px 