@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.logo
    display: inline-flex
    flex-wrap: nowrap
    align-items: center
    color: inherit
    @include media-breakpoint-up(sm)
        white-space: nowrap

    .logo__brand
        margin-left: $base-grid * 2
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
    // States
    &:hover
        color: inherit
        text-decoration: none
