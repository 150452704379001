@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'


.categories-list
  list-style: none
  margin: 0
  padding: 0
  // Elements
  .categories-list__item
    padding:
      left: $base-grid * 2
      right: $base-grid * 2
      top: calc($base-grid / 2)
      bottom: calc($base-grid / 2)