@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../styles/config'
@import '~bootstrap/scss/mixins'

    
    
.collection-nav
    display: grid
    grid:
            template:
                columns:  max-content 1fr max-content max-content
                areas: 'link link link' 'clear . copy' '. . save'
            gap: $base-grid * 2
    @include media-breakpoint-up(sm)
        grid:
            template:
                columns:  max-content 1fr max-content max-content
                areas: 'link link link link' 'clear . copy save'
            gap: $base-grid * 2
    @include media-breakpoint-up(md)
        grid:
            template:
                columns: max-content 1fr max-content max-content
                areas: 'clear link copy save'
    .collection-nav__link-area
        grid-area: link
        height: $base-grid * 5
        padding:
            left: $base-grid * 2
            right: $base-grid * 2
        background-color: white
        border: 1px solid #ECECEC
            radius: 2px 
    .collection-nav__button
        &--copy
            grid-area: copy
        &--clear
            grid-area: clear
        &--save
            grid-area: save