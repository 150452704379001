@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.header
    display: flex
    flex-wrap: wrap
    grid:
        template:
            columns: 1fr
        column-gap: $base-grid
    align-items: center
    grid:
        template:
            columns: 1fr auto
            areas: 'page tutorial logo'
    @include media-breakpoint-up(md)
        grid:
            template:
                columns: 1fr auto
                areas: 'page tutorial my-link logo'
    // Elems
    .header__logo
        justify-self: start
        flex-grow: 1
    .header__dropdown-collection
        grid-area: my-link
        margin:
            right: $base-grid

    // Elems
    .logo__mediathek
        display: inline-flex
        height: 100%
        align-items: center
        // background-color: $primary
        // color: white
        margin: 0
        padding:
            left: $base-grid * 2
            right: $base-grid * 2
            top: $base-grid
            bottom: $base-grid
        border-right: 1px solid #efefef
        @include media-breakpoint-up(sm)
            font-size: 1.25rem
            padding:
                left: $base-grid * 3
                right: $base-grid * 3
        img
            max-height: 1.7rem
            @include media-breakpoint-up(sm)
                max-height: 2.3rem