@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../styles/config'
@import '~bootstrap/scss/mixins'



.home-backlink
    color: black
    font-size: 16px
    @include media-breakpoint-down(sm)
        background-color: #EAEAEA
        padding: 5px 12px
        border-radius: 60px
    @include media-breakpoint-up(md)
        font-size: inherit
    &:before
        display: inline-block
        content: '\2039'
        width: $base-grid
        margin: 
            right: $base-grid
        vertical-align: baseline
        transform: translateY(-4%)